import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import './layout.scss'
import SEO from "./seo"

const Layout = ({ children, title }) => {
  return (
    <div className={`min-h-screen flex flex-col`}>
      <SEO title={title || ''} />
      <Header />
      <main className="flex-grow">{children}</main>
      <footer className="text-center p-4 text-gray-500 text-sm">© {new Date().getFullYear()} Wiri Salud</footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
