import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import logo from '../images/logo.svg'

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    setShowMenu(window.document.body.clientWidth >= 768)
  }, [])
  return (
    <nav className="flex items-center justify-between flex-wrap bg-white p-6">
      <Link className="mr-6" to="/">
        <img className="mr-2" width="150" src={logo} alt="" />
      </Link>
      <div className="block md:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-blue-600 border-blue-400" onClick={() => setShowMenu(!showMenu)}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className={`${showMenu ? 'block' : 'hidden'} md:flex md:items-center w-full md:w-auto`}>
        <div className="text-sm md:flex-grow">
          <Link className="block mt-4 md:inline-block md:mt-0 text-blue-600 hover:text-black mr-6" to="/medicamentos" activeClassName="font-semibold active">Vademecum</Link>
          <Link className="block mt-4 md:inline-block md:mt-0 text-blue-600 hover:text-black mr-6" to="/estudios" activeClassName="font-semibold active">Estudios</Link>
          <Link className="block mt-4 md:inline-block md:mt-0 text-blue-600 hover:text-black mr-6" to="/farmacias" activeClassName="font-semibold active">Farmacias</Link>
          <Link className="block mt-4 md:inline-block md:mt-0 text-blue-600 hover:text-black mr-6" to="/laboratorios" activeClassName="font-semibold active">Laboratorios</Link>
          <a className="block mt-4 md:inline-block md:mt-0 text-blue-600 hover:text-white mr-6" rel="noreferrer" href="https://app.wiri.la" target="_blank">Derivaciones</a>
        </div>
      </div>
    </nav>
  )
}
